import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const formInputs = document.querySelectorAll(".quote-form input, .quote-form select");

    this.closeModal();
    this._hideByClassName("rails-survey-ok-button");

    this.closeModalOnClickOutside = this.closeModalOnClickOutside.bind(this);
    document.addEventListener("click", this.closeModalOnClickOutside);

    if (formInputs) {
      formInputs.forEach((input) => {
        input.addEventListener("change", this.updateButtonState.bind(this, formInputs));
      });
    }
  }

  openInfoModal(event) {
    event.stopPropagation();
    var modals = this.element.getElementsByClassName("rails-modal");
    modals[0].style.display = "block";
  }

  openInfoComponentModal(event) {
    event.stopPropagation();
    var modals = document.getElementsByClassName("rails-modal");
    modals[0].style.display = "block";
  }

  openCarouselModal(event) {
    event.stopPropagation();
    var modals = this.element.getElementsByClassName("rails-modal");
    modals[0].style.display = "block";
    modals[0].id = event.currentTarget.dataset.index || 0;
    document.dispatchEvent(
      new CustomEvent("scrollElementIntoView", {
        detail: event.currentTarget.dataset.index || 0,
      })
    );
  }

  openModalDashboardRedirect(event) {
    event.stopPropagation();
    var modals = this.element.getElementsByClassName("rails-modal");
    modals[0].style.display = "block";

    // wait 3 seconds and redirect to nok dashboard.
    setTimeout(() => {
      window.location.href = window.location.href.replace("immediate_services/quote", "home");
    }, 3000);
  }

  openModalQuoteRedirect(event) {
    if (document.getElementById("at_need_quote_enquiry_read_eula").checked == true) {
      event.stopPropagation();
      var modals = this.element.getElementsByClassName("rails-modal");
      modals[0].style.display = "block";
    }
  }

  updateButtonState(formInputs) {
    const quoteButton = document.querySelector(".quote-form__submit");
    let allValid = true;

    formInputs.forEach((input) => {
      if (!input.validity.valid && input.required) {
        allValid = false;
      }
    });

    quoteButton.classList.toggle("disabled", !allValid);
  }

  openShareModal(event) {
    event.stopPropagation();
    var modal = document.getElementById("share-modal-" + event.currentTarget.id);
    modal.style.display = "block";
  }

  closeModalOnClickOutside(event) {
    var overlay = document.querySelector(".rails-modal");
    if (!this.element.contains(event.target) || event.target === overlay) {
      this.closeModal();
    }
  }

  closeModal() {
    this._hideByClassName("rails-modal");
    this._toggleCopyLink(false);
  }

  closeSurveyModal() {
    this._hideByClassName("rails-survey-modal");
  }

  copyLink(event) {
    navigator.clipboard.writeText(event.currentTarget.id);
    this._toggleCopyLink(true);
  }

  scrollToConsult() {
    const calendarCard = document.querySelector(".calendar-wrapper");
    calendarCard.scrollIntoView({ behavior: "smooth" });
  }

  sendRequest() {
    const requestButton = document.querySelector(".button-request");
    const exitButton = document.querySelector(".button-exit");
    const infoText = document.querySelector(".service-cta-modal-header");

    const secondHeader = infoText.dataset.secondHeader;

    requestButton.style.display = "none";

    exitButton.innerText = "Ok";
    exitButton.classList.add("button--primary");
    exitButton.classList.remove("button--secondary");

    infoText.innerHTML = secondHeader;
  }

  _toggleCopyLink(toggle) {
    var elements = document.getElementsByClassName("copy-link");
    var element_text = document.getElementById("copy-link-text");
    if (element_text && element_text.innerHTML == "Copied!") {
      element_text.innerHTML = "Copy Link";
      elements[0].classList.add("button--reverse");
      elements[0].classList.remove("button--secondary");
    } else if (toggle) {
      element_text.innerHTML = "Copied!";
      elements[0].classList.add("button--secondary");
      elements[0].classList.remove("button--reverse");
    }
  }

  _hideByClassName(className) {
    var modals = document.getElementsByClassName(className);
    for (let i = 0; i < modals.length; i++) {
      modals[i].style.display = "none";

      // Reset video if modal is closed.
      if (modals[i].querySelector("iframe") != null) {
        const iframe = modals[i].querySelector("iframe");
        const videoSrc = iframe.getAttribute("src");
        iframe.setAttribute("src", "");
        iframe.setAttribute("src", videoSrc);
      }
    }
  }
}
