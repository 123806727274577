import FileUploadController from "./file_upload";
import { validateFiles } from "./file_validation";

export default class extends FileUploadController {
  static targets = [...FileUploadController.targets];

  connect() {
    super.connect();
  }

  populateFileItem(files) {
    this.listTarget.classList.remove("hidden");
    const event = new CustomEvent("carousel:ImagesAdded", { detail: { files: files } });
    this.element.dispatchEvent(event);
  }

  async removeFile(event) {
    const fileIndexToRemove = this.element.querySelector(".hs-carousel-body").dataset.index;
    const filesArray = Array.from(this.fileTarget.files);

    if (fileIndexToRemove > -1) {
      const dataTransfer = new DataTransfer();
      filesArray.forEach((file, index) => {
        if (index != fileIndexToRemove) {
          dataTransfer.items.add(file);
        }
      });
      this.fileTarget.files = dataTransfer.files;
      this.filesArray = Array.from(dataTransfer.files);
    }

    this.populateFileItem(this.fileTarget.files);

    if (this.fileTarget.files.length === 0) {
      this.listTarget.classList.add("hidden");
    }

    await this.validateFiles();

    const validityEvent = new CustomEvent("input");
    this.fileTarget.dispatchEvent(validityEvent);
  }

  async validateFiles() {
    const isValid = await validateFiles(
      this.fileTarget.files,
      this.maxFiles,
      this.maxTotalSize,
      this.maxFileSize,
      this.acceptedFormats,
      this.minWidth,
      this.minHeight,
      this.fileTarget
    );

    if (isValid) {
      this.fileTarget.setCustomValidity("");
      this.wrapperTarget.classList.remove("error");
    }
  }
}
