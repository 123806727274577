import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    if (this.data.get("openValue") == this.element.id) {
      this.openTaskCategory();
    }
  }

  openTaskCategory() {
    const collapseButton = this.element.querySelector(".bfg-collapse");
    collapseButton?.click();
  }
}
